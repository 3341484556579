/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--20 pt--80" name={"uvod"}>
          
          <ColumnWrap className="column__flex el--1 pb--10 pt--60" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

              <Subtitle className="subtitle-box" content={"+420 797 811 2X0"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--08" content={"info@vase-stranky.com"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--1 pb--20 pt--20" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style2 fs--18" style={{"maxWidth":1115}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-szp6dj --parallax pb--80 pt--80" name={"paticka"} layout={"l1"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--3 pb--20 pt--20 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Kamenický</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">[[UNICreatedě]]</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"<span style=\"color: white;\">Holandská 854/1, Brno<br>+420 797 811 2X0<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Služby / Ceník</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Fotogalerie</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Zpět nahoru</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}